<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="net-service">
          <img src="../../../../public/imgs/chinaMap.jpg" alt="" />
          <p></p>
        </div>
        <div class="service">
          <div class="service-list">
            <div class="place-nav">
              <ul :style="{ left: `${piexl}rem` }">
                <li
                  v-for="(item, index) in contentData"
                  :key="index"
                  @click="enter(index)"
                >
                  <a :class="current == index ? 'active' : ''">{{item.name}}</a>
                </li>
              </ul>
            </div>
            <div @click="next" class="button-next"></div>
            <div @click="prev" class="button-prev"></div>
          </div>
          <div class="place-box" v-if="contentData && contentData.length > 0 && contentData[current].news.length > 0">
            <h6>{{ contentData[current].news[0].brief }}</h6>
            <div v-html="contentData[current].news[0].content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      current: 0,
      piexl: 0,
      contentData: [],
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getList() {
      let menu = this.getSubMenu.childList.filter(
        (item) => item.router === this.getCurrentRoute
      )[0];
      if (menu.Interface) {
        this.$post(menu.Interface).then((res)=>{
          this.contentData = res
        })
      }
    },
    enter(index) {
      this.current = index;
    },
    next() {
      if (this.piexl <= -(this.contentData.length - 3) * 4.5) return;
      this.piexl -= 4.5;
    },
    prev() {
      if (this.piexl === 0) return;
      this.piexl += 4.5;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      padding-top: 0.5rem;
      .net-service {
        img {
          width: 100%;
        }
        p {
          margin-bottom: 2rem;
        }
      }
      .service {
        .service-list {
          position: relative;
          margin-bottom: 1rem;
          margin-top: 1rem;
          .place-nav {
            width: 90%;
            height: 1.244rem;
            margin: 0 auto;
            overflow: hidden;
            ul {
              position: relative;
              width: 100%;
              height: 100%;
              z-index: 1;
              display: flex;
              transition-property: transform;
              box-sizing: content-box;
              transition: all 0.2s;

              li {
                text-align: center;
                flex-shrink: 0;
                height: 100%;
                position: relative;
                width: 4.5rem;
                a {
                  display: inline-block;
                  height: 1.244rem;
                  line-height: 1.244rem;
                  width: 3.4rem;
                  background: #f2f2f2;
                  color: #245f5c;
                  box-sizing: border-box;
                  border: 1px #245f5c solid;
                }
                .active {
                  border: 1px solid #ff9c00;
                  background: #ff9c00;
                  color: #fff;
                }
              }
            }
          }
          .button-next {
            position: absolute;
            top: 0.36rem;
            width: 0.4rem;
            height: 0.5rem;
            background: url(../../../../public/imgs/icon/d-right01.png) left
              center no-repeat;
            background-size: 0.311rem 0.5333rem;
            margin-top: 0;
            right: 0;
          }
          .button-prev {
            position: absolute;
            top: 0.36rem;
            width: 0.4rem;
            height: 0.6rem;
            background: url(../../../../public/imgs/icon/d-left01.png) left
              center no-repeat;
            background-size: 0.311rem 0.5333rem;
            margin-top: 0;
            left: 0;
          }
        }
        .place-box {
          h6 {
            font-size: 0.677rem;
            margin-bottom: 0.3rem;
            color: #333333;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>